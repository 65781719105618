import React from 'react';
import { Switch } from 'react-router-dom';

// Routes
import Homepage from '../pages/Open/Homepage';
import ImageView from '../pages/Open/ImageView';
import Route from './Route';

export default function Router() {
    return (
        <Switch>
            <Route path="/images/:id" component={ImageView} />
            <Route path="/" component={Homepage} />
        </Switch>
    );
}
