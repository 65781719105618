/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { IntlProvider } from 'react-intl';
import { useDispatch } from 'react-redux';

import { changeClientRequest } from '~/store/modules/client/actions';

import AppLocale from '../lngProvider';
import Routes from '../routes';
import GlobalStyle from '../styles/global';

export default function Pages() {
    const dispatch = useDispatch();

    const { location } = window;
    const currentAppLocale = AppLocale.en;

    useEffect(() => {
        const url = location.host.split('.');
        console.log('🚀 ~ file: index.js:20 ~ useEffect ~ url:', url);
        const subdomain = url[0];
        dispatch(changeClientRequest(subdomain || 'admin'));
    }, [location]);

    return (
        <IntlProvider
            locale={currentAppLocale.locale}
            messages={currentAppLocale.messages}
        >
            <>
                <Routes />
                <GlobalStyle />
            </>
        </IntlProvider>
    );
}
