import { takeLatest, put, all } from 'redux-saga/effects';

import { changeClientSuccess, changeClientFailure } from './actions';

export function* changePluginspace({ payload }) {
    try {
        const { subdomain } = payload;
        yield put(changeClientSuccess(subdomain));
    } catch (err) {
        yield put(changeClientFailure());
    }
}

export function loadClientSettings() {}

export function setGroup() {}

export default all([
    takeLatest('persist/REHYDRATE', setGroup),
    takeLatest('@settings/CHANGE_CLIENT_REQUEST', changePluginspace),
]);
