export function changeClientRequest(subdomain) {
    return {
        type: '@settings/CHANGE_CLIENT_REQUEST',
        payload: { subdomain },
    };
}

export function changeClientSuccess(subdomain) {
    return {
        type: '@settings/CHANGE_CLIENT_SUCCESS',
        payload: { client: { id: '', subdomain } },
    };
}

export function changeClientFailure() {
    return {
        type: '@settings/CHANGE_CLIENT_FAILURE',
    };
}
