import { addLocaleData } from 'react-intl';

import enLang from './entries/en_US';
import brLang from './entries/pt_BR';

const AppLocale = {
    br: brLang,
    en: enLang,
};

addLocaleData(AppLocale.br.data);
addLocaleData(AppLocale.en.data);

export default AppLocale;
