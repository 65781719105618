import { enUS, ptBR } from '@material-ui/core/locale';

const locales = { en_US: enUS, pt_BR: ptBR };

export default function GetMaterialLocale(settings) {
    const loc = settings ? settings.locale : null;
    const mat_loc = locales[loc ? loc.code : 'en_US'];

    return mat_loc;
}
