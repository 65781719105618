import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
    body {
        margin: 0px;
    }
    
    .toast-container {
        opacity: 0.9;
        height: 80px;
        padding: 20px;
        font-weight: bold;
    }

    .App {
        background-color: #282c34;
        min-height: 100vh;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        color: white;
    }
`;
