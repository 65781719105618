import React from 'react';
import { useSelector } from 'react-redux';
// import LocaleMessage from '~/components/LocaleMessage';

import logo from '~/assets/logo.svg';
import '~/css/App.css';

export default function Homepage() {
    const currClient = useSelector((state) => state.client.client);
    console.log('🚀 ~ file: index.js:10 ~ Homepage ~ currClient:', currClient);

    return (
        <div className="App">
            <header className="App-header">
                <img src={logo} className="App-logo" alt="logo" />
                <p>RTC WEB</p>
                <p>{process.env.REACT_APP_ENV_VERSION_NUMBER}</p>
                <p>{/* <LocaleMessage msg="message.go_home.title" /> */}</p>
                <>
                    {currClient && currClient.subdomain
                        ? currClient.subdomain.toUpperCase()
                        : ''}
                </>
            </header>
        </div>
    );
}
