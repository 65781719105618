/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Route } from 'react-router-dom';

import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider } from 'material-ui-pickers';
import PropTypes from 'prop-types';
import { ThemeProvider } from 'styled-components';

import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';

import defaultTheme from '~/styles/themes/defaultTheme';

import getMaterialLocale from '~/util/GetMaterialLocale';

import DefaultLayout from '../layouts/default';

export default function RouteWrapper({ component: Component, ...rest }) {
    const uiTheme = defaultTheme;

    const date_loc = getMaterialLocale();
    const applyTheme = createMuiTheme(uiTheme, date_loc);
    document.body.classList.remove('rtl');

    return (
        <ThemeProvider theme={uiTheme}>
            <MuiThemeProvider theme={applyTheme}>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                    <Route
                        {...rest}
                        render={(props) => (
                            <DefaultLayout {...props}>
                                <Component {...props} />
                            </DefaultLayout>
                        )}
                    />
                </MuiPickersUtilsProvider>
            </MuiThemeProvider>
        </ThemeProvider>
    );
}

RouteWrapper.propTypes = {
    adminOnly: PropTypes.bool,
    isPrivate: PropTypes.bool,
    hasGroup: PropTypes.bool,
    component: PropTypes.oneOfType([PropTypes.element, PropTypes.func])
        .isRequired,
    layout: PropTypes.string,
};

RouteWrapper.defaultProps = {
    isPrivate: false,
    hasGroup: false,
    layout: 'default',
    adminOnly: false,
};
