/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/forbid-prop-types */
import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';

import logo from '~/assets/logo.svg';
import api from '~/services/rtc-api';

export default function ImageView({ match }) {
    const { id } = match.params;

    const [file, setFile] = useState(null);

    function requestError(error) {
        if (error.response) {
            console.error(error);
        } else if (error.request) {
            console.error('errors.request');
        } else {
            console.error('errors.unknown');
        }
    }

    async function loadFile(_id) {
        if (_id) {
            await api
                .get(`files/${_id}`)
                .then((response) => {
                    setFile(response.data);
                })
                .catch((error) => requestError(error));
        } else {
            setFile(null);
        }
    }

    useEffect(() => {
        loadFile(id);
    }, [id]);

    return (
        <div>
            {file && file.url ? (
                <img
                    src={file && file.url ? file.url : logo}
                    className="Image viewer"
                    alt="img-viewer"
                />
            ) : (
                <h3>File not Found</h3>
            )}
        </div>
    );
}

ImageView.propTypes = {
    match: PropTypes.object.isRequired,
};
