import React, { useRef } from 'react';
import { isIOS, isMobile } from 'react-device-detect';

import PropTypes from 'prop-types';

import { Body } from './styles';

export default function Default({ children }) {
    const myRef = useRef(null);

    if (isIOS && isMobile) {
        document.body.classList.add('ios-mobile-view-height');
    } else if (document.body.classList.contains('ios-mobile-view-height')) {
        document.body.classList.remove('ios-mobile-view-height');
    }

    function isDesktop() {
        return window.innerWidth > 1024;
    }

    const drawerStyle = isDesktop() ? 'mini-drawer' : 'collapsible-drawer';

    return (
        <div className={`app-container ${drawerStyle}`}>
            <div ref={myRef} />
            <Body className="app-main-content">{children}</Body>
        </div>
    );
}

Default.propTypes = {
    children: PropTypes.element.isRequired,
};

Default.defaultProps = {};
