/* eslint-disable consistent-return */
import { produce } from 'immer';

const INITIAL_STATE = {};

export default function auth(state = INITIAL_STATE, action) {
    return produce(state, () => {
        switch (action.type) {
            default:
                return state;
        }
    });
}
