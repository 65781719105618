/* eslint-disable consistent-return */
import { produce } from 'immer';

const INITIAL_STATE = {
    loading: true,
    client: {
        id: null,
        subdomain: null,
    },
    theme: {},
    logo: {},
    admin_groups: [],
    app_list: [],
    active: { id: null, name: '' },
    locale: {
        languageId: 'english',
        locale: 'en',
        name: 'English',
        icon: 'us',
        code: 'en_US',
        format: 'en-US',
    },
};

export default function settings(state = INITIAL_STATE, action) {
    return produce(state, (draft) => {
        switch (action.type) {
            case '@settings/CHANGE_CLIENT_REQUEST': {
                draft.active = INITIAL_STATE.active;
                draft.loading = true;
                break;
            }
            case '@settings/CHANGE_CLIENT_SUCCESS': {
                draft.loading = false;
                draft.client = action.payload.client;
                draft.active = INITIAL_STATE.active;
                break;
            }
            case '@settings/CHANGE_CLIENT_FAILURE': {
                draft.client = {
                    id: null,
                    subdomain: 'new',
                };
                draft.loading = false;
                break;
            }
            default:
                return state;
        }
    });
}
